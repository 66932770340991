import React from 'react';
import BlogSearch from 'views/BlogSearch';
// import Main from 'layouts/Main';
// import Box from '@mui/material/Box';
// import { useTheme } from '@mui/material/styles';
// import Container from 'components/Container';

const ProjectPage = () => {
  // const theme = useTheme();

  return <BlogSearch />;
};

export default ProjectPage;
